@use "sass:map";
@use "variables/breakpoint";

$breakpoints: (
  tablet: breakpoint.$tablet,
  laptop: breakpoint.$laptop,
  desktop: breakpoint.$desktop,
);

$properties: (
  margin: m,
  padding: p,
);

$directions: (
  t: top,
  r: right,
  b: bottom,
  l: left,
  x: (
    left,
    right,
  ),
  y: (
    top,
    bottom,
  ),
);

@mixin generate-spacing-classes($prefix, $property, $breakpoint) {
  @media (min-width: map.get($breakpoints, $breakpoint)) {
    .#{$prefix}-#{$breakpoint} {
      #{$property}: var(--#{$property}-#{$breakpoint});
    }
    @each $dir, $dir-prop in $directions {
      @if $dir == x or $dir == y {
        .#{$prefix}#{$dir}-#{$breakpoint} {
          @each $axis in $dir-prop {
            #{$property}-#{$axis}: var(--#{$property}-#{$dir}-#{$breakpoint});
          }
        }
      } @else {
        .#{$prefix}#{$dir}-#{$breakpoint} {
          #{$property}-#{$dir-prop}: var(--#{$property}-#{$dir-prop}-#{$breakpoint});
        }
      }
    }
  }
}

@each $prop, $prefix in $properties {
  .#{$prefix} {
    #{$prop}: var(--#{$prop});
  }
  @each $dir, $dir-prop in $directions {
    @if $dir == x or $dir == y {
      .#{$prefix}#{$dir} {
        @each $axis in $dir-prop {
          #{$prop}-#{$axis}: var(--#{$prop}-#{$dir});
        }
      }
    } @else {
      .#{$prefix}#{$dir} {
        #{$prop}-#{$dir-prop}: var(--#{$prop}-#{$dir-prop});
      }
    }
  }

  @each $breakpoint, $value in $breakpoints {
    @include generate-spacing-classes($prefix, $prop, $breakpoint);
  }
}
