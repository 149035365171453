@use "variables/color";

.bottom {
  padding: 24px 0;
  text-align: center;

  &::before {
    border-top: 1px solid rgba(255, 255, 255, 0.2);
    content: "";
    display: block;
    margin: 0 auto;
    width: calc(100% - 30px);
  }

  @media (min-width: 768px) {
    border-top: 1px solid rgba(255, 255, 255, 0.2);
    padding: 0;

    &::before {
      display: none;
    }
  }

  @media (min-width: 992px) {
    padding: 12px 0;
  }
}

.container {
  align-items: center;
  display: flex;
  flex-direction: column;

  @media (min-width: 768px) {
    flex-direction: row;
    justify-content: space-between;
  }
}

.socialIconList {
  display: grid;
  grid-gap: 20px;
  grid-auto-flow: column;
  list-style: none;
  padding-left: 0;
  align-items: center;
}

.socialLink {
  color: #8d889e;
  display: flex;

  &:hover {
    color: color.$white;
  }
}

.copy {
  font-size: 15px;
  margin: 0;
}
