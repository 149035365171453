.copyrightText {
  margin: 24px auto 4px;
  text-align: center;
}

.link {
  color: inherit;
}

.divider {
  margin: 16px 16px 0;
  border-top: 1px solid rgba(255, 255, 255, 0.2);

  @media (min-width: 768px) {
    margin: 0;
  }

  @media (min-width: 992px) {
    margin-top: 12px;
  }
}
