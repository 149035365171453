@use "mixins/breakpoint";

.heading1 {
  font-size: 40px;
  font-weight: 700;
  line-height: 48px;

  @include breakpoint.desktop {
    font-size: 56px;
    line-height: 64px;
  }
}

.heading2 {
  font-size: 32px;
  font-weight: 700;
  line-height: 40px;

  @include breakpoint.desktop {
    font-size: 48px;
    line-height: 56px;
  }
}

.heading3 {
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;

  @include breakpoint.desktop {
    font-size: 32px;
    line-height: 44px;
  }
}

.heading4 {
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;

  @include breakpoint.desktop {
    font-size: 24px;
    font-weight: 600;
    line-height: 32px;
  }
}

.heading5 {
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;

  @include breakpoint.desktop {
    font-size: 22px;
    line-height: 32px;
  }
}

.heading6 {
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;

  @include breakpoint.desktop {
    font-size: 18px;
    line-height: 24px;
  }
}

.subtitleBig {
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;

  @include breakpoint.desktop {
    line-height: 24px;
  }
}

.subtitleNormal {
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
}

.subtitleSmall {
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
}

.bodyExtraBig {
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;

  @include breakpoint.desktop {
    font-size: 24px;
    line-height: 32px;
  }
}

.bodyBig {
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;

  @include breakpoint.desktop {
    line-height: 24px;
  }
}

.bodyNormal {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}

.bodySmall {
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
}

.bodyExtraSmall {
  font-size: 10px;
  font-weight: 600;
  line-height: 12px;
}
