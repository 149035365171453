$primaryBlue: #33adff;
$lightBlue: #d6eeff;
$club: #d518ff;
$green: #4dffc5;
$violet: #380089;
$darkViolet: #22173d;

$veryLightGray: #eeebf2;
$lightGray: #e8e7ec;
$gray: #bbb7c5;
$darkGray: #989898;
$almostBlack: #160829;

$pinkAlert: #ff006b;
$redAlert: #f00;
$orangeKids: #ffa42e;
$greenSuccess: #45a949;

$white: #fff;
$black: #000;
